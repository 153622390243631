export const data = [
  {
    id: 1,
    title: 'Можно ли собрать команду из студентов разных групп или курсов?',
    content: 'Да, но все они должны обучаться в одном вузе.',
  },
  {
    id: 2,
    title: 'Кто оплачивает проезд в Москву?',
    content: 'Обратитесь в администрацию своего университета. Мы вышлем письмо-приглашение для команд, которые пройдут в финал.',
  },
  {
    id: 3,
    title: 'Кто выйдет в финал?',
    content: 'В финал выйдут 3 лучшие команды в каждом из 6 кейсов, причём один вуз сможет представлять только одна лучшая команда в кейсе. Если по итогам отборочного этапа таблица лидеров будет выглядеть так: 1. РУТ (МИИТ) – Команда «Солнышки». 2. РУТ (МИИТ) – Команда «Шпалы». 3. ОмГУПС – Команда «Транспорт». 4. ДВГУПС – Команда «Тигры». То в финал выйдут только Солнышки, Транспорт и Тигры.',
  },
  {
    id: 4,
    title: 'Сколько кейсов может решать команда?',
    content: 'Только один. И только в одной команде может состоять каждый из участников соревнований.',
  },
];
